<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card v-if="group">
      <v-card-title class="display-2">
        <v-row>
          <v-col v-if="event && group" class="pa-0 pl-4 grow">{{ group.name }} @ {{ event.name }}</v-col>
          <v-btn v-if="!isEmbeddedInApp && me" outlined color="primary" @click="leaveGroup">Leave Group</v-btn>
          <v-btn v-if="!isEmbeddedInApp && (isAdminForTeam || hasFullAccess)" outlined color="primary" :to="{name:'profileGroupsEdit', params: {id: group.id}}">Edit</v-btn>
        </v-row>
      </v-card-title>

      <div v-if="event && status">
        <EventFriendReferral :event="event" :race="status.races && status.races.length == 1 ? status.races[0] : null" />
        <v-card-title class="subtitle">Connection status for {{event.name}}</v-card-title>
        <v-alert v-if="status.connected" type="success" class="mx-4">
          <p class="mb-0">This group is connected to the race(s): <strong>{{ status.races.map(x => x.name).join(', ') }}</strong>.</p>
          <p v-if="false && status.verification_code" class="mb-0">Group verification code is {{ status.verification_code}}.</p>
        </v-alert>      
        <v-card-text v-if="!isEmbeddedInApp">
          <EventGrid :items="[event]"/>
        </v-card-text>

        <div v-if="betaMode">
          <v-card-title class="subtitle pb-0">Team Feed</v-card-title>
          <v-card-text>
            Share messages, photos, and updates with your team members. This feed is only accessible by members of this team.
          </v-card-text>
          <p v-if="!isMember">The team feed is only available to team members.</p>
          <FeedList v-if="isMember" :feed-items="feed && feed.data" @refresh="loadFeed" :event="event" compact @likeItem="likeItem" :getItemCommentsFn="getItemComments" :postCommentOnItemFn="postCommentOnItem"/>
          <div v-if="user && isMember" class="mx-3">
            <PostCommentField @submit="postMessage" class="" />
            <div class="text-muted">Messages you post are visible to all current and future team members.</div>
          </div>
        </div>

        <div v-if="status.results && status.results.length">
          <v-card-title class="subtitle">Team Results</v-card-title>
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Race</th>
                  <th class="text-left">Position</th>
                  <th class="text-left">Score</th>
                  <th class="text-left">Details</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in status.results" :key="'act'+idx">
                  <td>{{ event.races.find(x => x.id == item.race_id).name }} </td>
                  <td><span v-if="item.pos">#{{ item.pos  }}</span></td>
                  <td>{{ $helpers.getValueForDisplay($options, event.races.find(x => x.id == item.race_id), item, event.unit) }} </td>
                  <td><v-btn text color="primary" @click="openResultDetailsPopup(item)">Details</v-btn> </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div v-if="results && results.length">
          <v-card-title class="subtitle">Team Member Results</v-card-title>
          <v-card-text>This table shows only those team members who have at least one qualifying activity.</v-card-text>
          <v-data-table
            :headers="[ 
              { value: 'race_id', text: 'Race', groupable: false }, 
              { value: 'name', text: 'Name' }, 
              { value: 'pos', text: 'Position' }, 
              { value: 'score_value', text: 'Score' }, 
              { value: 'details', text: 'Details' }, 
            ]"
            :items="results"
            item-key="id"
            sort-by="name"
            group-by="race_id"
            :items-per-page="50"
            :show-group-by="false"
          >
            <template v-slot:group.header="{items, isOpen, toggle}">
              <th colspan="4" class="white">
                Results for <strong>{{ event.races.find(x => x.id == items[0].race_id).name }}</strong>
              </th>
            </template>
            <template v-slot:item.pos="{ item }">
              <span v-if="item.pos">#{{ item.pos  }}</span>
            </template>
            <template v-slot:item.score_value="{ item }">
              {{ $helpers.getValueForDisplay($options, event.races.find(x => x.id == item.race_id), item, event) }}
            </template>
            <template v-slot:item.details="{ item }">
              <v-btn text color="primary" @click="openResultDetailsPopup(item)">Details</v-btn>
            </template>
          </v-data-table>
          
        </div>
        <RaceResultDetailsDialog :event="event" ref="detailsPopup"/>

        <v-card-title class="subtitle">Member status</v-card-title>
        <v-alert v-if="event.team_max_members && status.members.length >= event.team_max_members" type="info" tile>This team has reached the max team size of {{event.team_max_members}}. No new members may join anymore.</v-alert>
        <v-card-text>
          Use this table to verify the status for each team member.
          <span v-if="event.team_max_members">{{ status.members.length }} out of the maximum allowed {{ event.team_max_members }} members have joined this team.</span>
        </v-card-text>
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Status</th>
                <th class="text-left">Member</th>
                <th v-if="hasFullAccess" class="text-left">Code</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in status.members" :key="'act'+idx">
                <td>
                  <v-icon v-if="item.connected" small color="green" class="mr-1">fa-check-circle</v-icon>
                  <v-icon v-if="!item.connected" small color="red" class="mr-1">fa-times-circle</v-icon>
                  <span v-if="!item.connected && !item.id">Not yet joined</span>
                </td>
                <td>
                  {{ item.name }} 
                  <span v-if="item.captain" class="font-weight-bold">({{$t('profile.groups.team-captain')}})</span>
                </td>
                <td v-if="hasFullAccess">{{ item.verification_code }} </td>
                <td>
                  <v-btn v-if="me && me.id != item.id" color="primary" small text @click="requestFriend(item)">
                    <v-icon small class="me-2">fa-user-plus</v-icon> {{$t('results.details.add-friend')}}
                  </v-btn>
                  <v-btn v-if="hasFullAccess" text small color="primary" @click="removeMember(item)">{{$t('shared.disconnect')}}</v-btn> 
                  <v-btn v-if="hasFullAccess && !item.captain" text small color="primary" @click="makeCaptain(item)">Make Captain</v-btn> 
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div v-if="slots && slots.length > 0">
          <v-card-title class="subtitle">Relay Slots</v-card-title>
          <v-card-text>Assign team members to each relay slot. Please note that all slots must be assigned before the event starts. No changes can be made afterwards.</v-card-text>
          <v-card-text v-if="race && race.relay_join_instructions">
            <v-alert type="info">{{race.relay_join_instructions}}</v-alert>
          </v-card-text>
          <v-toolbar v-if="hasFullAccess" :elevation="0" class="px-4">
            <v-spacer/>
            <v-btn color="primary" @click="saveSlots">Save</v-btn>
          </v-toolbar>
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-if="hasSlotSports" class="text-left">Sport</th>
                  <th class="text-left">Slot</th>
                  <th v-if="hasSlotTimes" class="text-left">Time (your local time) *</th>
                  <th class="text-left">Team member</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(slotsInRace, race_id) in slotsGroupedByRace">
                  <tr :key="'race'+race_id"><td colspan="5">Race {{event.races.find(x => x.id == race_id).name}}</td></tr>
                <tr v-for="(item, idx) in slotsInRace" :key="'slot'+idx">
                  <td v-if="hasSlotSports">
                    <v-icon v-if="item.slot_type" :title="item.slot_type">{{$helpers.getActivityIcon(item.slot_type)}}</v-icon>
                    <span v-else>Any</span>
                  </td>
                  <td>{{ item.slot_name }}</td>
                  <td v-if="hasSlotTimes">{{ item.slot_start | localDate("L LT") }} - {{ item.slot_end | localDate("LT") }}</td>
                  <td>
                    <v-select
                      v-if="hasFullAccess"
                      :items="$helpers.prefixSelectListWith(profiles, {id: null, name:'Unassigned'})"
                      v-model="item.profile_id" 
                      item-value="id"
                      item-text="name"
                      placeholder="- Not yet assigned -"
                      solo
                      flat
                      hide-details
                    />
                    <span v-else>{{ (profiles.find(x => x.id == item.profile_id)||{}).name }}</span>
                    <v-btn v-if="!hasFullAccess && me && !item.profile_id" small @click="assignToMe(item)">Assign to me</v-btn>
                    <v-btn v-if="!hasFullAccess && me && item.profile_id == me.id" text color="primary" @click="unassign(item)">Unassign</v-btn>
                  </td>
                </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>   
      <v-card-actions>
        <v-btn text color="primary" @click="showShareInviteDialog=true">Invite</v-btn>
      </v-card-actions>   
    </v-card>

    <multi-step-dialog v-model="showShareInviteDialog" title="Share Invite Link" confirm-label="Close" single-step>
        <v-card-text v-if="status && status.join_url">
          Direct join link (to share with your team members):
          <v-text-field readonly v-model="status.join_url">
            <template v-slot:append>
              <v-dialog v-if="tenant.id == 'cofi'" max-width="400px">
                <template v-slot:activator="{ on }">
                  <v-btn icon color="primary" v-on="on" title="View QR code">
                    <v-icon>fadl fa fa-qrcode</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>Join Event QR</v-card-title>
                  <v-card-text>Share this code with the other participants so they can join as well.</v-card-text>
                  <QrCode :data="status.join_url" />
                </v-card>
              </v-dialog>
              <v-btn
                text
                color="primary"
                class="ma-0"
                @click="$helpers.copyToClipboard(status.join_url, $toast);">
                Copy
              </v-btn>
            </template>            
          </v-text-field>
        </v-card-text>


        <div v-if="group && group.type == 'CUSTOM_TEAM' && group.join_code" style="background-color: #ececec;">
          <v-card-text >
            <h2><v-icon>fal fa-lock</v-icon> Invite others to join</h2>
            <p>Please share this join code only with people you trust. Only you (as group owner) can see this.</p>
            Url:
            <div class="join-url">
              {{`${tenant.homeUrl}events/${event.id}`}}
            </div>
            Join Code:
            <div class="join-code">
              {{group.join_code}}
            </div>
          </v-card-text>  
        </div>
    </multi-step-dialog>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import groupService from "@/services/groupService";
import eventService from "@/services/eventService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import EventGrid from "@/components/EventGrid.vue";
import EventCard from "@/components/EventCard.vue";
import EventFriendReferral from "@/components/EventFriendReferral.vue";
import QrCode from '@/components/generic/QrCode.vue'
import FeedList from '@/components/FeedList.vue';
import PostCommentField from '@/components/PostCommentField.vue'
import RaceResultDetailsDialog from "@/components/RaceResultDetailsDialog";
import MultiStepDialog from '@/components/generic/MultiStepDialog.vue';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileGroupsView",
  components: {
    QrCode,
    EventGrid,
    EventCard,
    EventFriendReferral,
    RaceResultDetailsDialog,
    MultiStepDialog,
    FeedList,
    PostCommentField,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      groupId: null,
      group: null,
      event: null,
      status: null,
      feed: null,
      slots: null,
      results: null,
      showShareInviteDialog: false,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async loadData(groupId, eventId) {
      this.groupId = groupId;
      //try {
      //  this.group = (await profileService.getGroup(groupId)).data;
      //}
      //catch { }
      this.event = (await eventService.get(eventId)).data;
      this.status = (await profileService.getGroupEventStatus(groupId, eventId)).data;
      /* event organizer can't read the group so we get it from the status instead */
      this.group = this.status.group;
      if (this.status && this.status.races && this.status.races.length == 1) {
        //this.race = this.status.races[0];
        this.slots = this.status.slots;
      }
      this.slots = [];
      if (this.status && this.status.slots) {
        this.slots = this.status.slots;
      }
      if (this.betaMode && this.isMember) {
        await this.loadFeed();
        console.log('/// this.feed', this.feed);
      }

      this.results = (await profileService.getGroupMemberEventResults(groupId, eventId)).data.data;
      //console.log('/// this.results', this.results);
    },

    async loadFeed() {
      this.feed = (await profileService.getFeedGroup(this.group.id)).data;
    },

    openResultDetailsPopup(result) {
      this.$refs.detailsPopup.race = this.event.races.find(x => x.id == result.race_id);
      this.$refs.detailsPopup.open(result);
    },

    async requestFriend(profile) {
      if (confirm(this.$t('profile.friends.request-as-friend-msg', {user: profile.name }))){
        const response = (await profileService.requestFriend(profile.id)).data;
        this.$helpers.toastResponse(this, response, this.$t('profile.friends.request-confirmation'));
        if (response.status == 'OK') {
          this.$router.replace({ name: "profileFriends" });
        }
      }
    },

    async leaveGroup() {
      if (confirm(`Are you sure you want to leave this group?\n\nIf you want to re-join later you must disconnect from the event first and then re-connect using your original verification code/link.`)) {
        const response = (await profileService.disconnectMeFromGroupAndEvent(this.groupId, this.event.id)).data;
        this.$helpers.toastResponse(this, response, `Successfully left group`);
        if (response.status == 'OK') {
          this.$router.replace({ name: "profileGroups" });
        }
      }
    },
    async removeMember(member) {
      if (confirm(`Are you sure you want to remove ${member.name} from this group and the results?`)) {
        const response = (await profileService.disconnectGroupAndEvent(this.groupId, member.id, this.event.id)).data;
        this.$helpers.toastResponse(this, response, `Successfully removed ${member.name} from this group`);
        await this.getProfile();
      }
    },
    async makeCaptain(member) {
      if (confirm(`Are you sure you want to make ${member.name} team captain of this group?`)) {
        const response = (await profileService.makeTeamCaptain(this.groupId, member.id, this.event.id)).data;
        this.$helpers.toastResponse(this, response, `Successfully made ${member.name} team captain`);
        await this.getProfile();
      }
    },

    async assignToMe(slot) {
      slot.profile_id = this.me.id;
      this.saveSlots();
      await this.getProfile();
    },

    async unassign(slot) {
      slot.profile_id = null;
      this.saveSlots();
      await this.getProfile();
    },

    async saveSlots() {
      const response = (await profileService.putGroupSlotAssignment(this.groupId, this.event.id, this.slots)).data;
      this.$helpers.toastResponse(this, response, 'Successfully saved slot configuration');
    },

    async likeItem(itemId) {
      let response = await profileService.likeFeedGroupItem(this.group.id, itemId);
      this.$helpers.toastResponse(this, response.data, 'Thanks! Item has been liked.');
      this.loadFeed();
      //return response.data;
    },
    async getItemComments(itemId) {
      let response = await profileService.getFeedGroupItemComments(this.group.id, itemId);
      return response.data.data;
    },
    async postCommentOnItem(itemId, message) {
      let response = await profileService.postFeedGroupItemComment(this.group.id, itemId, message);
      this.$helpers.toastResponse(this, response.data, 'Thanks! Your comment has been placed.');
      if (response.data.status == 'OK') {
          this.loadFeed();
      }
      return response.data;
    },
    async postMessage(message) {
      if (message) {
        const response = (await profileService.postFeedGroupComment(this.groupId, message)).data;
        this.$helpers.toastResponse(this, response, 'Successfully shared your message.');
        if (response.status == 'OK') {
          this.feed = null;
          this.loadFeed();
        }
      }
    },

    async getProfile() {
      if (this.user) {
        await this.loadData(this.$route.params.id, this.$route.params.eventId);
      }
    },
  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
    breadcrumbItems() {
      return !this.group || !this.event ? [] : [
        { text: 'Profile', exact: true, to: { name: 'profile' } },
        { text: 'Groups', exact: true, to: { name: 'profileGroups' } },
        { text: this.group.name, exact: true, to: { name: 'profileGroupsView', params: { id: this.group.id } } },
        { text: this.event.name, disabled: true },
      ];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    me() {
      return !this.status ? null : this.status.me;
    },

    isAutoTeam() {
      return this.group && (this.group.type == 'AUTO_TEAM');
    },

    isMember() {
      return this.status.me;
    },

    isAdminForTeam() {
      return this.isAutoTeam
        ? (this.status.me && this.status.me.captain) || (this.event && this.event.join_select_team && this.group.is_owner)
        : this.group.is_owner || (this.status.me && this.status.me.captain)
        ;
    },

    hasFullAccess() {
      return !this.status ? false : (this.status.event_admin || this.isAdminForTeam);
    },

    profiles() {
      return !this.status || !this.status.members ? [] : this.status.members.filter(x => x.connected && x.id);
    },

    hasSlotSports() {
      return this.slots && this.slots.some(x => x.slot_type);
    },
    hasSlotTimes() {
      return this.slots && this.slots.some(x => x.slot_start || x.slot_end);
    },

    slotsGroupedByRace() {
      return this.slots ? this.slots.reduce((acc, item) => {
        (acc[item.race_id] = acc[item.race_id] || []).push(item)
        return acc
      }, {}) : [];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss" scoped>
.join-url {
  line-height: 2em;
  padding: 0 1em;
  font-weight: bold;
  border: dotted 3px #ccc;
  margin-bottom: 10px;
}
.join-code {
  font-size: 3em;
  letter-spacing: 1em;
  line-height: 2em;
  padding: 0 1em;
  font-weight: bold;
  text-transform: uppercase;
  border: dotted 3px #ccc;
}
</style>

